$box-background : #f3f3f3;

body{
	margin-top:15px;

}




/* barra */



.navbar-default .navbar-collapse, .navbar-default .navbar-form {
//	background-color: red;
}

.navbar-default .navbar-toggle .icon-bar {
	background-color: #f9f9f9;
}
.navbar-default {
	background-color: $aita_red;
}

.navbar-default .navbar-nav>li>a {
	color: white;
	font-weight: bold;
	padding-top: 8px;
	padding-bottom: 8px;
		@media screen and (max-width: $screen-md-min) {
		padding-left: 8px;
		padding-right: 8px;
	}
}

.navbar-default .navbar-nav > .active > a {
	color: $aita_red;
}

.right-menu > .nav-stacked {
	background-color: $box-background;
}

.right-menu > .nav-pills>li>a {
    border-radius: 0px;
    color: black;
}

.nav-pills>li.active>a {
	color: $aita_red;
	background-color: $box-background;
	font-weight: bold;
}

// main

main {
	margin-bottom: 20px;
}

// footer

footer {
	// margin-top:20px;
}


// utility

.utility {
	float: left;
	width: 100%;
}


.breadcrumb {
	padding: 3px 15px 3px 15px;
    // background-color: #fafafa;
    font-size: 0.9em;
    float: left;
}

#section-tools {
	float: right;
}


// box

.box {
	margin-left: -15px;
	background-color: $box-background;
}

.box-header {
	a{color: white;}
	height-min: 25px;
	color: white;
	background-color: $aita_red;
	padding: 5px;
	font-size: 1.3em;
	font-style: italic;
	font-weight: bold;
}

.box-content {
	padding: 10px;
}

#box-news {
	margin-left: 0px;
	background-color: $box-background;
}
// Footer

#copyright, #email {
	font-size: 10px;
	line-height: 15px;
	color: #777;
	display: inline;
	margin-right: 15px;
}

/* #fontsizer { display: block; float: right; height: 40px; }
#fontsizer ul { margin: 0; padding: 0; list-style: none; position: relative; width: 51px; height: 23px; background: url(../images/fontresizer.gif) no-repeat; }
#fontsizer ul li { display: block; position: absolute; margin: 0; padding: 0; }
#fontsizer ul li a { display: block; width: 100%; height: 100%; background-color: transparent; background-attachment: fixed; background-image: none; background-repeat: repeat; }
#fontsizer ul li#decrease { left: 0px; top: 0px; width: 8px; height: 23px; }
#fontsizer ul li#reset { left: 16px; top: 0px; width: 12px; height: 23px; }
#fontsizer ul li#increase { left: 37px; top: 0px; width: 14px; height: 23px; } */
